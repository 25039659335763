import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import "bootstrap/dist/css/bootstrap.css";
import {
  BsPencilFill,
  BsFillPlusCircleFill,
  BsTrashFill,
} from "react-icons/bs";

import AddGuestModal from "../../components/common/add-guest-modal";
import EditGuestModal from "../../components/common/edit-guest";
import DeleteGuestModal from "../../components/common/delete-guest";

import { formatDate } from "../../utils/formatTime";

import { toast } from "react-toastify";

import { useQuery } from "react-query";
import useAxios from "../../api/useAxios";
import { CSVLink, CSVDownload } from "react-csv";

import { Button } from "reactstrap";

const GuestListing = () => {
  document.title = "Guest | SNB Capital";

  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [modalData, setModalData] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState(false);

  let api = useAxios();

  const { isLoading, isError, data, isSuccess, refetch } = useQuery(
    `guest-listing`,
    async () => api.get("/guest-list/")
  );

  const guests = data?.data?.data ?? [];

  console.log(guests);

  const setEditModalData = (values) => {
    setModalData(values);
  };

  const toggleEditModal = () => {
    if (isEditModalOpen) {
      setModalData(false);
    }
    setEditModalOpen(!isEditModalOpen);
  };

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!isDeleteModalOpen);
  };

  useEffect(() => {
    if (modalData) {
      toggleEditModal();
    }
  }, [modalData]);

  useEffect(() => {
    if (deleteModalData) {
      setDeleteModalOpen(true);
    }
  }, [deleteModalData]);

  const guestsColumns = useMemo(
    () => [
      {
        header: "#",
        size: 30,
        Cell: ({ row }) => <span>{parseInt(row.id, 10) + 1}.</span>,
      },
      {
        accessorKey: "name",
        header: "Guest Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "phone",
        header: "Phone Number",
      },
      {
        accessorKey: "vip",
        header: "VIP",
        Cell: ({ row }) => <span>{row?.original?.vip ? "Yes" : "No"}</span>,
      },
      {
        accessorKey: "position",
        header: "Position",
      },
      {
        accessorKey: "company",
        header: "Company",
      },
      {
        accessorKey: "invitation",
        header: "Invitation Status",
        Cell: ({ row }) => (
          <div>
            {row?.original?.invitation == "Pending" ? (
              <span className="badge rounded-pill bg-secondary">Pending</span>
            ) : row?.original?.invitation == "Accepted" ? (
              <span className="badge rounded-pill bg-success">Accepted</span>
            ) : (
              <span className="badge rounded-pill bg-danger">Declined</span>
            )}
          </div>
        ),
      },
      {
        header: "Edit",
        size: 100,
        Cell: ({ row }) => (
          <BsPencilFill
            onClick={() => setEditModalData(row.original)}
            style={{ cursor: "pointer" }}
          />
        ),
      },
      {
        header: "Delete",
        size: 100,
        Cell: ({ row }) => (
          <BsTrashFill
            onClick={() =>
              setDeleteModalData({
                id: row.original.id,
              })
            }
            style={{ cursor: "pointer" }}
          />
        ),
      },
    ],
    []
  );

  const handleResponse = (status, res) => {
    let unexpectedValue = true;

    if (status === "success") {
      toast.success("Done");
      unexpectedValue = false;

      setAddModalOpen(false);
      setEditModalOpen(false);
      setDeleteModalOpen(false);
      setModalData(false);
      setDeleteModalData(false);
    }
    if (status === "error") {
      if (res?.response?.data?.message) {
        toast.error(res?.response?.data?.message);
        unexpectedValue = false;
      } else {
        unexpectedValue = true;
      }
    }

    if (unexpectedValue) {
      toast.error("Something went wrong");
    }
  };

  const handleDeleteResponse = (status, res) => {
    if (status === "success") {
      toast.success("Guest Deleted");
      setDeleteModalOpen(false);
      refetch();
    }
    if (status === "error") {
      toast.error("Something went wrong");
    }
  };

  console.log(guests)
  
  const acceptedGuests = guests.filter(guest => guest.invitation === "Accepted");

  const csvData = [
    ['#', 'Name', 'Email', 'Phone', 'Position', 'Company', 'VIP', 'Time', 'Date'],

    ...acceptedGuests.map((guest, index) => [index + 1, guest.name, guest.email, guest.phone, guest.position, guest.company,  guest.vip ? "Yes" : "No", guest.accepted_time, guest.accepted_date]),
  ];


  return (
    <div className="admin-content">
      <div
        className="container-fluid breadcrumb-color mt-4 mb-3"
        id="breadCrumbStyling"
      >
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb d-block">
            <li
              className="breadcrumb-item text-white text-center active fs-4 py-3 px-2 d-flex align-items-center justify-content-between"
              aria-current="page"
              id="breadCrumbTitleStyling"
            >
              <span>Guests</span>
              <span>
                <BsFillPlusCircleFill
                  onClick={() => setAddModalOpen(true)}
                  className="cursor-pointer"
                />
              </span>
            </li>
          </ol>
        </nav>
      </div>

      <div className="container-fluid p-4" id="formStyling">
      <div style={{ marginBottom: "10px" }}>
      <CSVLink 
        data={csvData}

        filename="SNB Capital Investment Tycoons Forum 2024 - Accepted Guests.csv"
      >
        
        <Button color="success">
          Download Registered Guests List
        </Button>
      </CSVLink>

        </div>
        <MaterialReactTable
          columns={guestsColumns}
          data={guests} 
          muiTablePaperProps={{
            elevation: 10,
          }}
          state={{ isLoading: isLoading }}
        />
      </div>

      <AddGuestModal
        isOpen={isAddModalOpen}
        toggle={() => {
          setAddModalOpen(false);
        }}
        handleResponse={handleResponse}
      />

      <EditGuestModal
        isOpen={isEditModalOpen}
        toggle={toggleEditModal}
        data={modalData}
        handleResponse={handleResponse}
      />

      <DeleteGuestModal
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
        handleResponse={handleDeleteResponse}
        data={deleteModalData}
      />
    </div>
  );
};

export default GuestListing;
