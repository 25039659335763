import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";

import { addGuestSchema } from "../../validations/validation-schemas";
import { useMutation } from "react-query";

import useAxios from "../../api/useAxios";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddGuestModal = ({ isOpen, toggle, day, handleResponse }) => {
  const [modalStyles, setModalStyles] = useState({
    zIndex: "1",
  });

  const onSubmit = () => {
    console.log("in submit");
    if (values.email == "" && values.phone == "") {
      toast.error("Email or Phone is required");
    } else {
      addGuestMutation.mutate(values);
    }
  };

  let api = useAxios();

  const addGuest = (payload) => {
    console.log(payload);
    return api.post("/create-guest/", payload);
  };

  const addGuestMutation = useMutation(addGuest, {
    onSuccess: (res) => {
      resetForm();
      handleResponse("success", res);
    },
    onError: (res) => {
      handleResponse("error", res);
      // toast.error(res.response.data.message);
    },
  });

  const modules = {
    toolbar: [
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      ["clean"],
      [{ indent: "-1" }, { indent: "+1" }],
    ],
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      position: "",
      company: "",
      email: "",
      phone: "",
      vip: "False",
    },
    validationSchema: addGuestSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    console.log("errors", errors);
    console.log("values", values);
  }, [errors, values]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
      <ModalHeader toggle={toggle}>
        <h4 className="text-primary mb-0">Add New Guest</h4>
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <>
            <div className="row">
              <div className="col-md-6 col-12">
                <Label className="form-label text-start mt-3 mb-1">Name</Label>
                <Input
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  className={
                    errors.name && touched.name
                      ? "form-control form-control-icon is-invalid col-6"
                      : "form-control form-control-icon green-border col-6"
                  }
                  id="guestName"
                  placeholder="Enter guest name"
                  name="name"
                />
                {errors.name && touched.name && (
                  <div className="invalid-feedback">{errors.name}</div>
                )}
              </div>
              <div className="col-md-6 col-12">
                <Label className="form-label text-start mt-3 mb-1">Email</Label>
                <Input
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="email"
                  className={
                    errors.email && touched.email
                      ? "form-control form-control-icon is-invalid col-6"
                      : "form-control form-control-icon green-border col-6"
                  }
                  id="guestEmail"
                  placeholder="Enter guest email"
                  name="email"
                />
                {errors.email && touched.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>
              <div className="col-md-6 col-12">
                <Label className="form-label text-start mt-3 mb-1">
                  Phone Number
                </Label>
                <Input
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  className={
                    errors.phone && touched.phone
                      ? "form-control form-control-icon is-invalid col-6"
                      : "form-control form-control-icon green-border col-6"
                  }
                  id="guestPhone"
                  placeholder="+966XXXXXXXX"
                  name="phone"
                />
                {errors.phone && touched.phone && (
                  <div className="invalid-feedback">{errors.phone}</div>
                )}
              </div>
              <div className="col-md-6 col-12">
                <Label className="form-label text-start mt-3 mb-1">
                  Position
                </Label>
                <Input
                  value={values.position}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  className={
                    errors.position && touched.position
                      ? "form-control form-control-icon is-invalid col-6"
                      : "form-control form-control-icon green-border col-6"
                  }
                  id="guestPosition"
                  placeholder="Enter guest position"
                  name="position"
                />
                {errors.position && touched.position && (
                  <div className="invalid-feedback">{errors.position}</div>
                )}
              </div>
              <div className="col-md-6 col-12">
                <Label className="form-label text-start mt-3 mb-1">
                  Company
                </Label>
                <Input
                  value={values.company}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  className={
                    errors.company && touched.company
                      ? "form-control form-control-icon is-invalid col-6"
                      : "form-control form-control-icon green-border col-6"
                  }
                  id="guestCompany"
                  placeholder="Enter guest company"
                  name="company"
                />
                {errors.company && touched.company && (
                  <div className="invalid-feedback">{errors.company}</div>
                )}
              </div>
              <div className="col-md-6 col-12">
                <Label className="form-label text-start mt-3 mb-1">VIP</Label>
                <Input
                  value={values.vip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="select"
                  className={
                    errors.vip && touched.vip
                      ? "form-control form-control-icon  is-invalid col-6"
                      : "form-control form-control-icon  green-border col-6"
                  }
                  id="guestCompany"
                  name="vip"
                  style={{ border: "1px solid " }}
                >
                  <option value="False">No</option>
                  <option value="True">Yes</option>
                </Input>
                {errors.vip && touched.vip && (
                  <div className="invalid-feedback">{errors.vip}</div>
                )}
              </div>
            </div>
          </>
        </ModalBody>
        <ModalFooter>
          {addGuestMutation.isLoading ? (
            <p>Processing...</p>
          ) : (
            <>
              <button
                onClick={toggle}
                type="button"
                className="btn admin-grey-btn"
              >
                Close
              </button>
              <button type="submit" className="btn admin-green-btn">
                Save
              </button>
            </>
          )}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddGuestModal;
