import * as yup from "yup";

export const guestLoginSchema = yup.object().shape({
  phone: yup.string().required("Please Enter Phone"),
});

export const adminLoginSchema = yup.object().shape({
  username: yup.string().required("Required"),
  password: yup.string().required("Required"),
});

export const guestSchema = yup.object().shape({
  title: yup.string().required("Required"),
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  phone: yup.string().required("Required"),
  passport: yup.string().required("Required"),
  authority: yup.string().required("Required"),

  arrivalFrom: yup.string().required("Required"),
  arrivalTo: yup.string().required("Required"),
  arrivalFlightNumber: yup.string().required("Required"),
  arrivalDate: yup.date().required("Required"),
  arrivalTime: yup.string().required("Required"),
  arrivalCarrier: yup.string().nullable(),
  arrivalBoardingTime: yup.string().nullable(),
  arrivalGate: yup.string().nullable(),
  arrivalAirportLocation: yup.string().nullable(),
  arrivalSeat: yup.string().nullable(),

  departureFrom: yup.string().required("Required"),
  departureTo: yup.string().required("Required"),
  departureFlightNumber: yup.string().required("Required"),
  departureDate: yup.date().required("Required"),
  departureTime: yup.string().required("Required"),
  departureCarrier: yup.string().nullable(),
  departureBoardingTime: yup.string().nullable(),
  departureGate: yup.string().nullable(),
  departureAirportLocation: yup.string().nullable(),
  departureSeat: yup.string().nullable(),
});

export const guestEditSchema = yup.object().shape({
  position: yup.string().required("Required"),
  name: yup.string().required("Required"),
  company: yup.string().required("Required"),
});
export const addGuestSchema = yup.object().shape({
  position: yup.string(),
  name: yup.string(),
  company: yup.string(),
  email: yup.string().email("Must be a valid email"),
  phone: yup.string(""),
});

export const summarySchema = yup.object().shape({
  day: yup.string().required("Required"),
  title: yup.string().required("Required").nullable(),
  location: yup.string().nullable(),
  date: yup.string().nullable(),
  starting_time: yup.string().nullable(),
  ending_time: yup.string().nullable(),
});
